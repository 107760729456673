import React, { useEffect, useState } from "react";
import { useLocation,NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Theme1 from '../../imgs/theme1.png'
import Theme2 from '../../imgs/theme2.png'
import CircularProgress from '@mui/material/CircularProgress';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Autocomplete, Box, Button, FormControl, IconButton, LinearProgress, Modal, Switch, Tab, Tabs, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { ModalStyle } from "../../theme/ModalStyles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import IconLink from "../../components/ui/IconLink";
import MyIconButton from "../../components/ui/MyIconButton";
import ConfirmationModal from '../../components/ui/ConfirmationModal'
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { colors } from "../../theme/Colors";
import API from "../../api/Api_1_3";
import { ContactsOutlined, VisibilityOutlined } from "@mui/icons-material";
import { frFR } from "@mui/x-data-grid";
import DefaultProfilePic from "../../imgs/user_gray.png";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { columns } from "../../data/cartesAbonnesDataForContacts";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";

function Page() {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const location = useLocation();
    const pathname = location.pathname.split("/");
    const pageId = pathname[3];
    const [id,setId] = useState("")
    const [imgUrl,setImgUrl] = useState("")
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([])
    const [companySelected, setCompanySelected] = useState("")
    const [loading,setLoading] = useState(false);
    const [cardTheme,setCardTheme] = useState("");
    const [groupSelected,setGroupSelected] = useState(null)
    const [formData, setFormData] = useState({
        title: "",
        banner: "",
        description: "",
        hot_line_number: "",
        card_theme:"",
        can_save_contacts:"",
        can_save_contacts_on_consultation:"",
        group_id: groupSelected,
    });

    const [displayType,setDisplayType] = useState("EXTERNAL");
    const [canSaveContacts,setCanSaveContacts] = useState(true);
    const [canSaveContactsOnConsult,setCanSaveContactsOnConsult] = useState(true);
    const [groups,setGroups] = useState([])
    const [selectedIDs, setSelectedIDs] = useState([]);

    async function getGroups(id) {
        try {

            const response = await Api.get(`/bdisplay-groups?enterprise_id=${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                    authorization: `Bearer ${access_token}`,
                },
            });

            setGroups(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    async function getPage() {
        try {

            const response = await Api.get(`/pages/${pageId}`, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                    authorization: `Bearer ${access_token}`,
                },
            });

            setFormData(response.data.page ?? []);
            setImgUrl(response.data.page?.url_banner);
            setCompanySelected(response.data.page.company_id ?? '');
            getCompany(response.data.page.enterprise.commercial_name)
            setCardTheme(response.data?.page?.card_theme)
            setDisplayType(response.data.page.display_type)
            setCanSaveContacts(response.data?.page.can_save_contacts)
            setGroupSelected(response.data?.page?.group?.id ?? null)
            getGroups(response.data.page.enterprise.id)
            setLinks(response.data?.page?.links ?? [])
            setUsers(response.data.page.users ?? [])
            const ids = response.data.page.users.map(user => user.id)
            setSelectedIDs(ids)

        } catch (error) {
            console.log(error);
        }
    }

    //on page load
    useEffect(() => {
        Api.get(Urls.GET_ENTREPRISE_TABLE, {
            headers: {
                "Content-type": "application/json",
                accept: "application/json",
                authorization: `Bearer ${access_token}`,
            },
        })
            .then((response) => {
                setCompanies(response.data.enterprises);
                if(pageId !== 'add'){
                    getPage()
                } 
                else {
                    return;
                }
            })
            .catch((error) => {
                if (error?.response?.status === 403) {
                    toast.error("Une erreur est survenue");
                } else {
                    toast.error(error?.response?.data?.message);
                }
            });
            Api.get(Urls.GET_POSSIBLE_LINKS, {
                headers: {
                  "Content-type": "application/json",
                  accept: "application/json",
                },
              })
                .then((response) => {
                  setPossibleLinksList(response.data.links);
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    localStorage.removeItem("user");
                    navigate("/login", { replace: true });
                    navigate(0, { replace: true });
                  }
                  if (error?.response?.status === 403) {
                    toast.error("Une erreur est survenue");
                  } else {
                    toast.error(error?.response?.data?.message);
                  }
                });
            
    },[pageId]);


    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (event) => {
        setFormData({
            ...formData,
            banner: event.target.files[0],
        });
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const pathname = location.pathname.split("/");
        const pageId = pathname[3];

        const data = new FormData();
        if(formData.hot_line_number == null || formData.hot_line_number === ''){
            data.delete('hot_line_number')
            delete formData.hot_line_number
          }
          else {
            data.append("hot_line_number", formData.hot_line_number);
          }

          if(formData.description == null || formData.description === ''){
            data.delete('description')
            delete formData.description
          }
          else {
            data.append("description", formData.description);
          }

          if(formData.banner == null || formData.banner === ''){
            data.delete('banner')
            delete formData.banner
          }
          else {
            data.append("banner", formData.banner);
          }

          if(canSaveContacts == null || canSaveContacts === ''){
            data.delete('can_save_contacts')
            delete formData.can_save_contacts
          }
          else {
            if(canSaveContacts === true || canSaveContacts === "true"){
                data.append("can_save_contacts", 1);
            }
            else{
                data.append("can_save_contacts", 0);
            }
          }

          if(canSaveContactsOnConsult == null || canSaveContactsOnConsult === ''){
            data.delete('can_save_contacts_on_consultation')
            delete formData.can_save_contacts_on_consultation
          }
          else {
            if(canSaveContactsOnConsult === true || canSaveContactsOnConsult === "true"){
                data.append("can_save_contacts_on_consultation", 1);
            }
            else{
                data.append("can_save_contacts_on_consultation", 0);
            }
          }

          if(groupSelected == null || groupSelected === ''){
            data.delete('group_id')
            delete formData.group_id
          }
          else {
                data.append("group_id", groupSelected);           
          }

      
        data.append("title", formData.title);
        data.append("card_theme",cardTheme)
        data.append("company_id", companySelected)
        data.append("display_type",displayType)
        data.append("display_type",displayType)

          if(links.length !== 0){
            // Append each link object to the formData object
            links.forEach((link, index) => {
              data.append(`links[${index}][link_id]`, link.link_id);
              data.append(`links[${index}][title]`, link.title);
              data.append(`links[${index}][value]`, link.value);
            });
          }

        try {
            const response = await Api.post(

                pageId === "add" ? "/pages" : "/pages/update/" + pageId ,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${access_token}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                toast.success("Operation success");
                setTimeout(() => {
                    if(pageId === 'add'){
                        navigate("/admin/"+ response.data.page.id + '/services');
                    }
                    else{
                        navigate("/admin/pages");
                    }
                }, 2000);
            } else {
                // Handle errors, e.g., display an error message
                toast.error("Operation echoué");
            }
        } catch (error) {
            toast.error(error.response?.data?.message);
            
        }
        setLoading(false)
    };

    const handleUpdate = async (values) => {
        setLoading(true)
        const pathname = location.pathname.split("/");
        const pageId = pathname[3];

        try {
            const response = await API.patch(

                "/pages/links/" + pageId ,
                {
                    link_id: values.link_id,
                    value: values.value
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${access_token}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                toast.success("Operation success");
                getPage()
            } else {
                // Handle errors, e.g., display an error message
                toast.error("Operation echoué");
            }
        } catch (error) {
            toast.error(error.response?.data?.message);
            
        }
        setLoading(false)
    };


    const handleThemeToggle = (e)=>{
        setCardTheme(e.target.value)
      }

    //   NEW: Social Links
  const [inLinks, setInLinks] = useState(true);
  const [inDocuments, setInDocuments] = useState(false);
  const [currentVariantLink, setCurrentVariantLink] = useState("primary");
  const [currentVariantDocument, setCurrentVariantDocument] = useState("secondary");
  const [links, setLinks] = useState([]);
  const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();
  const [allowOtherField, setAllowOtherField] = useState();
  //modal config
  const [openAddLink, setOpenAddLink] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [possibleLinksList, setPossibleLinksList] = useState();

  const [didAddLink, setDidAddLink] = useState(false);
  const [data, setData] = useState();
  const [progress, setProgress] = useState(0);


  const handleButtonVariantChangeLink = () => {
    if ((currentVariantDocument === "secondary" && currentVariantLink === "primary") || (currentVariantDocument === "primary" && currentVariantLink === "secondary")) {
      setCurrentVariantLink("primary");
      setCurrentVariantDocument("secondary");
    }
  }

  //initial values link
  const initialValuesAddLink = {
    link_id: "",
    value: "",
    title: "",
  };
  
  //validation schema link fields
  const validationSchemaAddLink = Yup.object({
    link_id: Yup.string().required("Ce champ est obligatoire"),
    value: Yup.string().required("Ce champ est obligatoire").url(),
    title: Yup.string().required("Ce champ est obligatoire"),
  });

    
  //add link modal
    const handleOpenAddLink = () => {
      if (data?.id === null || undefined) {
        toast.error("Aucune ligne séléctionnée");
        return;
      }
      setOpenAddLink(true);
    };
    
    const handleCloseAddLink = () => setOpenAddLink(false);
  
    //delete link modal
    const handleOpenDelete = () => {
      setOpenDelete(true);
    };
    const handleCloseDelete = () => setOpenDelete(false);
  
    //update link modal
    const handleOpenUpdate = () => {
      setOpenUpdate(true);
    };
    const handleCloseUpdate = () => setOpenUpdate(false);

  

    //delete link
  const deleteLink = async (id_link) => {
    const pathname = location.pathname.split("/");
    const pageId = pathname[3];
    
    if(pageId !== 'add'){
      setLoading(true)

        try {
            const response = await API.delete(

                "/pages/links/" + pageId + "/" + id_link ,
                {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${access_token}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                toast.success("Operation success");
                setLinks(links.filter((l)=> l.link_id !== id_link))
                getPage()
            } else {
                // Handle errors, e.g., display an error message
                toast.error("Operation echoué");
            }
        } catch (error) {
            toast.error(error.response?.data?.message);
            
        }
        setLoading(false)
    }
  };

   //add link
   const addLink = async (values) => {
    setLinks([
      ...links,
      {
        link_id: values.link_id,
        title: values.title,
        value: values.value,
      }
    ]);

    setDidAddLink(!didAddLink);
    handleCloseAddLink();
    if(pageId !== 'add'){
        setLoading(true)
        try {
            const response = await API.post(

                "/pages/links/" ,
                {
                    page_id: pageId,
                    title: values.title,
                    link_id: values.link_id,
                    value: values.value
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${access_token}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                toast.success("Operation success");
                getPage()
            } else {
                // Handle errors, e.g., display an error message
                toast.error("Operation echoué");
            }
        } catch (error) {
            toast.error(error.response?.data?.message);
            
        }
        setLoading(false)
  };
  }

  const updateLink = (values) => {
    const updatedLinks = links.map((l) => {
      if (l.link_id === values.link_id) {
        return { ...l, title: values.title, value: values.value };
      }
      return l;
    });
    setLinks(updatedLinks);
    handleCloseUpdate()
  };
  
  const getLogoUrl = (slug) => {
    const link = possibleLinksList?.find((link) => link.title === slug);
    return link ? link.url_logo : "";
  };


  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  //data grid pages configuration
  const [value, setValue] = useState('one');
  const [users, setUsers] = useState([])
  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddColls = async () => {
    const payload = {
      "bdisplay_id": pageId,
    }
    if(selectedIDs.length !== 0){
      payload.users = selectedIDs
    }
   await API.post('/pages/attach-users', payload,
    {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      }
    }
    )
    .then((response) => {
      toast.success(response.data.msg)
      setPage(0);
      setIsLoading(false);
      getPage()
    })
    .catch((error) => {
      if (error.response.status === 403) toast.error("Une erreur est survenue");
      else toast.error(error.response.data.message);
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login", { replace: true });
        navigate(0, { replace: true });
      }
    });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCompany = (value) => {
    setIsLoading(true);
    Api.get(Urls.GET_COLLABS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { 
        perPage: perPage,
        collaboratedEnterpriseCommercialName: value
      },
    })
      .then((response) => {
        setPage(0);
        setIsLoading(false);
        setRowsAPI(response.data.users);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }

  const getCompanyDetails = async (id) => {
    try {
      const res = await API.get('/enterprises/' + id,{
        headers:{
            'Content-Type':'application/json',
            'Authorization': `Bearer ${access_token}`,
            'Accept': 'application/json'
        }
      } )

      const data = {
        hot_line_number: res?.data?.data?.phone_number,
      }

      Object.keys(data).forEach((key) => {
        if (data[key] !== null || data[key] !== '') {
          setFormData(prevValues => ({
            ...prevValues,
            [key]: data[key]
          }));
        }
      });
      




    } catch (error) {
      
    }
  }
    

  useEffect(() => {
    setIsLoading(true);
    Api.get(Urls.GET_COLLABS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { perPage: perPage },
    })
      .then((response) => {
        setPage(0);
        setIsLoading(false);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [access_token, perPage, navigate]);

    return (
        <div>
            <ToastContainer
                style={{ top: "5rem", width: "300px" }}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className="title-button">
                <h1 className=" font-bold text-lg my-3">{pageId !== 'add' ? 'Modifier la' : 'Ajouter une'}  page</h1>
            </div>

            <Box mt={2} mb={3} >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tabs"
              >
                <Tab
                  value="one"
                  label="Paramètres"
                />
                <Tab value="two" label="Utilisateurs" />
                <Tab
                  value="three"
                  label="Affecter les Utilisateurs"
                  wrapped
                />
              </Tabs>
            </Box>

            {value === 'one' &&
            <form onSubmit={handleSubmit} className="table-container">

                {/* Select Company  */}
                
                {!formData.id ? <div style={{ display: 'flex', flexDirection: "column", gap: 2, margin:"20px 0px" }}>
                    <label className="font-bold self-center text-purple-500">Selectionner l'entreprise *</label>
                    <select
                        required
                        style={{
                            "padding": "0.25rem", "borderRadius": "0.375rem", "borderWidth": "1px", "borderColor": "black"
                        }}
                        value={companySelected}
                        onChange={e => {
                            setCompanySelected(e.target.value)
                            getGroups(e.target.value)
                            getCompanyDetails(e.target.value)
                        }
                        } >
                        <option value="" disabled>selectionner enterprise</option>
                        {companies.map((company, index) => (
                            <option value={company.id} key={index}>{company.commercial_name}</option>
                        ))}
                    </select>
                </div> : null }

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin:"20px 0px"
                    }}
                >
                    <label style={{ fontSize: 15, fontWeight: 700,alignSelf:'center' }} className="text-purple-500">
                        Titre de la page *
                    </label>
                    <input
                        placeholder="Titre de la page"
                        name="title"
                        className="default-input"
                        value={formData.title}
                        onChange={handleTextChange}
                        required
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: "column", gap: 2, margin:"20px 0px" }}>
                    <label style={{ fontSize: 15, fontWeight: 700 }} className="self-center text-purple-500">Display type *</label>
                    <select
                        style={{
                            "padding": "0.25rem", "borderRadius": "0.375rem", "borderWidth": "1px", "borderColor": "black"
                        }}
                        value={displayType}
                        onChange={e => setDisplayType(e.target.value)} 
                        required
                    >
                        <option value="" disabled>display type</option>
                        <option value="INTERNAL">Internal</option>
                        <option value="EXTERNAL">External</option>

                    </select>
                </div>

                <div style={{ display: 'flex', flexDirection: "column", gap: 2, margin:"20px 0px" }}>
                    <label className="font-bold self-center text-purple-500">Selectionner le groupe</label>
                    <select
                        style={{
                            "padding": "0.25rem", "borderRadius": "0.375rem", "borderWidth": "1px", "borderColor": "black"
                        }}
                        value={groupSelected}
                        onChange={e => {
                            setGroupSelected(e.target.value)
                        }
                        } >
                        <option value="" disabled selected >selectionner le groupe</option>
                        {groups.map((group, index) => (
                            <option value={group.id} selected={group.id === groupSelected} key={index}>{group.name}</option>
                        ))}
                    </select>
                </div>

                <div
                    style={{
                        marginTop: 12,
                        display: "flex",
                        flexDirection: "column",
                        margin:"20px 0px"
                    }}
                >
                    <label style={{ fontSize: 15, fontWeight: 700 }} className="self-center text-purple-500">
                        Téléphone
                    </label>
                    <input
                        type="number"
                        placeholder="Téléphone"
                        name="hot_line_number"
                        className="default-input"
                        value={formData.hot_line_number}
                        onChange={handleTextChange}
                    />
                </div>

                <div
                    style={{
                        marginTop: 12,
                        display: "flex",
                        flexDirection: "column",
                        margin:"20px 0px"
                    }}
                >
                    <label style={{ fontSize: 15, fontWeight: 700 }} className="self-center text-purple-500">
                        Banner de la page {!formData.id ? '*' : null} (recommandé 1366 x 768 px) png, jpeg
                    </label>
                    <input
                        type="file"
                        name="banner"
                        className="default-input"
                        onChange={handleImageChange}
                        required={formData.id ? false : true}
                    />
                    { !formData.banner && (imgUrl && pageId) ? <a href={imgUrl} target="_blank" rel="noreferrer" className="my-1 flex items-center gap-1 current_link font-medium">
                    <p className="text-sm underline bg-transparent underline-offset-2">Consulter l'actuelle</p>
                    <OpenInNewIcon sx={{'fontSize':'large'}}/>
                    </a> : null}
                </div>

                <div
                    style={{
                        marginTop: 12,
                        display: "flex",
                        flexDirection: "column",
                        margin:"20px 0px"
                    }}
                >
                    <label style={{ fontSize: 15, fontWeight: 700 }} className="self-center text-purple-500">
                        Description
                    </label>
                    <textarea
                        placeholder="Description de la page"
                        name="description"
                        rows={10}
                        className="default-input"
                        value={formData.description}
                        onChange={handleTextChange}
                    />
                </div>

                <h6 className="text-start text-purple-500 my-8 font-bold text-lg">Activation du formulaire</h6>

                <Box
                    style={{ display: 'flex', flexDirection: "column", gap: 2, margin:"20px 0px", alignItems:'start' }}
                >
                    <label style={{ fontSize: 15, fontWeight: 500 }} className="self-start text-purple-500">Lors du téléchargement de brochure </label>
                    <Switch
                      checked={canSaveContacts}
                      onChange={(e) => {
                        setCanSaveContacts(e.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>

                <Box
                  style={{ display: 'flex', flexDirection: "column", gap: 2, margin:"20px 0px", alignItems:'start' }}
                >
                  <label style={{ fontSize: 15, fontWeight: 500 }} className="self-start text-purple-500">Lors de la Consultation </label>
                  <Switch
                    checked={canSaveContactsOnConsult}
                    onChange={(e) => {
                      setCanSaveContactsOnConsult(e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Box>

                    {/* Links */}

                    <Box mt={10} mb={10} maxWidth={'sm'}>
                    <h6 className="text-start text-purple-500 my-8 font-bold text-lg">Ajouter votre liens</h6>

          {inLinks && (
            <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
              {links?.map((l) => (
                <Box key={l.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <IconLink title={l.title} icon={getLogoUrl(l.title)} value={l.value} />
                  <Box>
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="edit"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        if (l.slug === "other") {
                          setAllowOtherField(true);
                        } else {
                          setAllowOtherField(false);
                        }
                        handleOpenUpdate();
                      }}
                    />
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="delete"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        handleOpenDelete();
                      }}
                    />
                  </Box>
                </Box>
              ))}

              <ConfirmationModal title="Supprimer un lien" message="Êtes-vous sûr de bien vouloir supprimer ce lien ?" acceptLabel="oui" refuseLabel="non" open={openDelete} onClose={handleCloseDelete} acceptMethod={deleteLink} acceptMethodParams={selectedLinkToDelete?.link_id} />

              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={handleOpenAddLink}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>

              {/* Modal add link */}
              <Modal open={openAddLink} onClose={handleCloseAddLink}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter un lien
                  </Typography>
                  {/* form add link */}
                  <Formik initialValues={initialValuesAddLink} validationSchema={validationSchemaAddLink} onSubmit={addLink}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = {};
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");
                              setFieldValue("url_logo", value ? value.url_logo : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value.title);
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddLink} variant="third">
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form add link */}
                </Box>
              </Modal>

              {/* modal update link */}
              <Modal open={openUpdate} onClose={handleCloseUpdate}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Mettre à jour un lien
                  </Typography>

                  {/* form update link */}
                  <Formik initialValues={{ link_id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value ?? "", title: selectedLinkToDelete?.title ?? "" }} validationSchema={validationSchemaAddLink}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            defaultValue={{ id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value, title: selectedLinkToDelete?.title }}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.title === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = { link_id: "" };
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value ? "Site web" : "");
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button
                            variant="primary"
                            onClick={() => {
                              if (isObjEmpty(errors)) {
                                updateLink(values)
                                handleUpdate(values);
                              }
                            }}
                          >
                            Mettre à jour
                          </Button>
                          <Button onClick={handleCloseUpdate} variant="third">
                            Annuler
                          </Button>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                        <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                      </Form>
                    )}
                  </Formik>
                  {/* end form update link */}
                </Box>
              </Modal>
              {/* end modal update link  */}
            </Box>
          )}

                </Box>

                    {/* NEW: theme */}
                    <h6 className="text-start text-purple-500 my-8 font-bold text-lg">Affichage du Bdisplay</h6>
 
                <div
                    style={{
                      marginTop: 12,
                      display: "flex",
                    }}
                >
       
                   
                <fieldset
                    style={{
                      marginTop: 12,
                      display: "flex",
                      alignItems:'center',
                      justifyContent:'center'
                    }}
                >
       
            <label htmlFor="type1" className="flex flex-col items-center justify-center gap-3">
            <img src={Theme1} alt="theme_1" width={300} height={300}/>
          <input
            type="radio"
            checked={cardTheme === 'TYPE1'}
            id="type1"
            name="theme"
            style={{'width':'20px','height':'20px'}}
            value={'TYPE1'}
            onChange={handleThemeToggle}
          />
          Shadow Card
            </label>
            <label htmlFor="type2" className="flex flex-col items-center justify-center gap-3">
            <img src={Theme2} alt="theme_2" width={350} height={350}/>
          <input
            type="radio"
            checked={cardTheme === 'TYPE2'}
            id="type2"
            name="theme"
            style={{'width':'20px','height':'20px'}}
            value={'TYPE2'}
            onChange={handleThemeToggle}
          />
          Overlay Card
            </label>
        
                </fieldset>
                </div>

                <div className=" flex justify-center md:justify-end lg:justify-end items-center w-full mt-16 gap-4">
                   { loading === true ? <CircularProgress/> : <button
                        type="submit"
                        className="bg-[#7B3FFF] px-4 py-2 text-white rounded-lg"
                    >
                        Enregistrer
                    </button>}
                    <NavLink
                        to={"/admin/pages" }
                        style={{ backgroundColor: "#9A9A9A", width: "auto" }}
                        className=" px-4 py-2 text-white rounded-lg"
                    >
                        Annuler
                    </NavLink>
                    
                </div>
            </form>
            }

            {value === 'two' && 
            <Box display={'flex'} sx={{width:'100%'}} gap={{
              xs: 5,
              sm: 5,
              md: 4,
              lg: 5,
            }} flexWrap={'wrap'} alignItems={'center'} justifyContent={{
              xs: 'center',
              sm: 'center',
              md: 'start',
              lg: 'start',
            }}>
              {users.length !== 0 ? users.map((user) => (
                <div key={user.id} className="flex w-[17rem] items-center justify-center gap-4">
                   <div
                      key={user.id}
                      style={{
                        color: "#353535",
                        textDecoration: "none",
                        padding: 12,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                      className=" drop-shadow-lg shadow-gray-400 shadow-lg rounded-xl"
                    >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={user?.url_profile_picture ?? DefaultProfilePic}
                            alt="profile_picture"
                            style={{
                              backgroundSize: "cover",
                              width: 250,
                              height: 250,
                              borderRadius: 10,
                              marginTop:'0'
                            }}
                          />
                        </div>
                          <div className="flex flex-col my-4 items-center">
                            <h3 className="font-bold text-lg pt-3">{user.first_name + " " + user.last_name}</h3>
                            <h5 className="font-medium pt-2 whitespace-pre-wrap text-center">{user.function}</h5>
                          </div>                  
                      {/* <div
                        style={{
                          marginTop: 6,
                          width:'100%',
                          display: "flex",
                          alignItems:'center',
                          justifyContent: "end",
                          gap:'.5rem'
                        }}
                      >
                        <NavLink to={user.url_vcf_file}>
                          <Button variant="primary" startIcon={<ContactsOutlined/>} >Ajouter Contact</Button>
                        </NavLink>
                        <NavLink to={process.env.REACT_APP_PROFILE + user.url_profile} target="_blank">
                          <Button variant="secondary" startIcon={<VisibilityOutlined/>} >Voir Profile</Button>
                        </NavLink>
                      </div> */}
                    </div>
                </div>
              )) : <Typography variant="p">Aucun utilisateurs ajoutée</Typography>}
            </Box>
            }

            {value === 'three' && 
            <Box>

            <Box sx={DataGridHeaderStyle}>
            <StripedDataGrid
              //stripes
              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
              // sx={{ maxWidth: "1180px" }}
              sx={{ maxWidth: "100%" }}
              rowHeight={40}
              onSelectionModelChange={(ids) => {
                setSelectedIDs(ids);
              }}
              loading={isLoading}
              disableSelectionOnClick
              components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: EmptyDataGridOverlay,
              }}
              columns={columns}
              //data to feed datagrid
              rows={rowsAPI}
              //allow selection
              checkboxSelection
              selectionModel={selectedIDs}
              //how many rows in a page ?
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
              }}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              pageSize={perPage}
              rowsPerPageOptions={pageNumbers}
              //mode
              paginationMode="server"
              //total number of rows per search
              rowCount={total}
              //onpage change
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
              pagination
              page={page}
            />
            </Box>

            <Box display={'flex'} justifyContent={'end'} mt={2}>
              <Button variant="primary" onClick={handleAddColls}>Soumettre</Button>
            </Box>
            </Box>
            }
        </div>
    );
}

export default Page;
