import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import { useAuthContext } from "../../hooks/useAuthContext";
import EditIcon from '@mui/icons-material/Edit';
import "./styles/StyledTable.css";
import { Add, Close, Delete,FilterList  } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import QrCodeIcon from '@mui/icons-material/QrCode';
import TimelineIcon from '@mui/icons-material/Timeline';
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import MzErrorText  from '../../components/ui/MzErrorText'
import { Box, Button, FormControl, TextField, Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { Urls } from "../../api/Urls";
import Checkbox from "@mui/material/Checkbox";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { colors } from "../../theme/Colors";
import VisibilityIcon from '@mui/icons-material/Visibility';

function Pages() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const [initialValues, setInitialValues] = useState({
    display_type: "",
    title:'',
    enterprise_id:""
  });
  const [pages, setPages] = useState([]);
  const [ondelite, setOnDelete] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  const [companies, setCompanies] = useState([])

  const navigate = useNavigate();

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const handleCheckAllChange = (event) => {
    setSelectedIds(event.target.checked ? pages.map((page) => page.id) : []);
  }

  const handleDelete = async (id) => {
    try {
      const res = await Api.delete('/pages/' + id,{
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
      const data = await res.data
        if(data.status === 'success'){
          const newPages = pages.filter(page => page.id !== id)
          setPages(newPages)
          toast.success('Deleted Successfully !', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress:undefined ,
            theme: "dark",
            });
        }
    } catch (error) {
      toast(error.message)
    }
    setOnDelete(false)
  }

  const onSubmitSearchBdisplay = async (values,token) => {
    
    const display_type = values.display_type === 'Interieur' ? 'INTERNAL' : values.display_type === 'Exterieur' ? 'EXTERNAL' : null

    Api.get("/pages"  , {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      params: { 
        display_type: display_type,
        title: values.title !== '' ? values.title : null,
        enterprise_id: values.enterprise_id !== '' ? values.enterprise_id : null
       } 
    })
      .then((response) => {
        setPages(response.data?.pages)
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const handleExportBdisplays = async (values) =>{
    try {
      const params = values.length !== 0 ? { bdisplay_ids: values } : null;

      const response = await Api.get('/bdisplay-export', {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: `Bearer ${access_token}`,
        },
        params: params,
        responseType: 'blob', // Ensure Axios treats the response as a blob
      });
  
      const blob = response.data; // Use response.data to access the blob
  
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `Bdisplays.xlsx`);
  
      document.body.appendChild(link);
  
      setTimeout(() => {
        link.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 100); // Delay the click to ensure the link is appended
    } catch (error) {
      toast.error('Failed to export contacts');
      console.error('Error fetching the file:', error);
    }
  }

  const validationSchema = Yup.object({
    display_type: Yup.string(),
    title: Yup.string(),
    enterprise_id: Yup.string()
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitSearchBdisplay(values, access_token);
    },
  });

  useEffect(() => {
    Api.get("/pages", {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setPages(response.data.pages ?? []);
      })
      .catch((error) => {
        console.log(error);
      });

      Api.get(Urls.GET_ENTREPRISE_TABLE, {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
        .then((response) => {
            setCompanies(response.data.enterprises);
        })
        .catch((error) => {
            if (error?.response?.status === 403) {
                toast.error("Une erreur est survenue");
            } else {
                toast.error(error?.response?.data?.message);
            }
        });
  }, []);

  
  return (
    <div className=" bg-white rounded-lg">
      <div style={{ display: "flex", flexDirection:'column', justifyContent: "space-between", marginBottom: 24,gap:'1rem' }}>
        <Box display={"flex"} gap={3} flexDirection={{xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row'}} alignItems={'start'} justifyContent={'space-between'}>
          <h1 className="font-bold text-xl">Tous les Displays</h1>
          <Box display={'flex'} gap={1} alignItems={'start'}>
          <Button startIcon={<FileDownloadIcon/>} variant="form" sx={{fontSize:'1rem', fontWeight:500, borderRadius:'8px', color: colors.light_purple, backgroundColor: '#F5F5F5', border: '1px solid #F5F5F5', '&:hover': { backgroundColor: '#F5F5F5', border: '1px solid #F5F5F5', color: colors.light_purple}}} onClick={()=> handleExportBdisplays(selectedIds)}>
            Exporter
          </Button>
            <NavLink
              to={"/admin/pages/empty/add"}
              className=" bg-[#F5F5F5] text-[#964CFC] p-2 font-medium rounded-lg"
            >
              <Add/>
              Créer Bdisplay vide
            </NavLink>
            <NavLink
              to={"/admin/pages/add"}
              className="bg-[#964CFC] p-2 font-medium text-white rounded-lg"
            >
              <Add/>
              Créer BDisplay
            </NavLink>
          </Box>
        </Box>

        <Box className="filter" display="flex" flexDirection={{xs:'row',sm:'row',md:'row',lg:'row'}} alignItems={'start'} justifyContent={{xs:'start',sm:'start',md:'space-between',lg:'space-between'}}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchBdisplay(initialValues, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <FormControl>
              <Box display="flex">

                {/*Search fields */}

                <Box display="flex" maxWidth="1200px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                  
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="display_type"
                      component={Autocomplete}
                      options={['Interieur','Exterieur']}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          display_type: value,
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Display Type" sx={{width:200}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Display Type" component={MzErrorText} />
                  </Box>

                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="Entreprise"
                      component={Autocomplete}
                      options={companies.map(c => ({ label: c.commercial_name, value: c.id }))} // Map each company to an object with label and value properties
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => option.value === value.value} // Use the value property for comparison
                      onChange={(event, value) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          enterprise_id: value?.value || '', // Use the value property of the selected option
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Entreprise" sx={{width:200}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Entreprise" component={MzErrorText} />
                  </Box>

                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <TextField
                      placeholder="Title"
                      name="title"
                      onChange={(event, value) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          title: event.target.value,
                        }));
                      }}
                      sx={{'::placeholder':{'fontWeight':600}}}
                    />
                    <ErrorMessage name="title" component={MzErrorText} />
                  </Box>

                  <Box marginRight="1rem" mb display={'flex'} gap={1}>
                    <Button type="submit" onClick={()=> onSubmitSearchBdisplay(initialValues, access_token)} variant="primary">
                      <FilterList/>
                    </Button>
                    <Button type="submit" onClick={() => {
                       setFieldValue('display_type', '');
                       setFieldValue('title', '');
                       setFieldValue('enterprise_id', '');
                       onSubmitSearchBdisplay(values,access_token)
                      }} variant="primary">
                      <Close/>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </FormControl>
          )}
        </Formik>
        </Box>

      </div>
      <table className="styled-table">

        <thead>
          <tr className=" bg-slate-600">
            <th className="text-lg">
              <Checkbox
                checked={selectedIds.length === pages.length}
                onChange={handleCheckAllChange}
              /></th>
            <th className="text-lg">Titre</th>
            <th className="text-lg">Entreprise</th>
            <th className="text-lg">Display Type</th>
            <th className="text-lg">QR Code</th>
            <th className="text-lg">Statistics</th>
            <th className="text-lg">Services</th>
            <th className="text-lg">Action</th>
          </tr>
        </thead>

        <tbody className="w-[1000px]" >
          {pages?.length === 0 ? (
            <tr>chargement</tr>
          ) :
            pages.length === 0 ? (
              <tr>empty</tr>
            )
              : (
                pages?.map((page) => {
                  return (
                    <tr key={page.id} >
                      <th className="font-normal">
                        <Checkbox
                          checked={selectedIds.includes(page.id)}
                          onChange={(event) => handleCheckboxChange(event, page.id)}
                        />
                      </th>
                      <th className="font-normal">
                        <p
                          className={`primary-button primary-link-pages truncate`}
                        >
                        {page.identification ? page.identification + " " +  page.title : page.title}
                        </p>

                      </th>

                      <th style={{ fontWeight: "normal", verticalAlign: "middle" }} className="truncate">{page.enterprise?.commercial_name ?? 'N/A'}</th>
                      <th style={{ fontWeight: "normal" }}>{page.display_type}</th>
                      <th className="underline underline-offset-4 text-center font-light text-purple-800"><a href={page?.url_qrcode} className="self-center h-full w-full" target="_blank" rel="noreferrer" ><QrCodeIcon/></a></th>
                      <th style={{fontWeight:'normal'}}>
                        <NavLink
                          to={"/admin/page/" + page.id+"/statistics"}
                          className={`primary-button primary-link-pages underline text-purple-800 font-bold underline-offset-4`}
                        >
                        <TimelineIcon/>
                      </NavLink>
                      </th>
                      <th style={{fontWeight:'normal'}}>
                        <NavLink
                          to={"/admin/" + page.id+"/services"}
                          className={`primary-button primary-link-pages underline text-purple-800 font-bold underline-offset-4`}
                        >
                        Consulter
                      </NavLink>
                      </th>
                      <th className="flex items-center justify-center h-full gap-2">
                        <NavLink
                          to={process.env.REACT_APP_BDISPLAY + page.id}
                          className="bg-purple-800 p-2 h-fit hover:bg-opacity-80 hover:transition-all hover:ease-out hover:duration-200 text-white rounded-lg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <VisibilityIcon/>
                        </NavLink>
                        <NavLink
                          to={"/admin/pages/" + page.id}
                          className="bg-purple-800 p-2 h-fit hover:bg-opacity-80 hover:transition-all hover:ease-out hover:duration-200 text-white rounded-lg"
                        >
                          <EditIcon/>
                        </NavLink>
                          <button
                          onClick={() => setPageToDelete(page.id)}
                          className="bg-purple-800 p-2 h-fit hover:bg-opacity-80 hover:transition-all hover:ease-out hover:duration-200 text-white rounded-lg"
                          
                        >
                          <Delete/>
                          
                          </button>
                        <ConfirmationModal
                            title="Supprimer une page"
                            message="Êtes-vous sûr de bien vouloir supprimer cette page ?"
                            acceptLabel="Oui"
                            refuseLabel="Non"
                            open={pageToDelete === page.id}
                            onClose={() => setPageToDelete(null)}
                            acceptMethod={() => handleDelete(page.id)}
                          />
                      </th>
                    </tr>
                  );
                })
              )}
        </tbody>
      </table>
      <ToastContainer/>
    </div>
  );
}

export default Pages;